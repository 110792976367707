"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  SHOW: 'SHOW',
  CLOSE: 'CLOSE',
  REFRESH: 'REFRESH'
};
exports.default = _default;